import localforage from "localforage";

const set = async (key, value) => {
	try {
		//console.debug(key)
		let res = await localforage.setItem(key, value);
		return res;
	} catch (e) {
		throw new Error(e);
	}
};

const get = async (key) => {
	try {
		let res = await localforage.getItem(key);
		//console.debug(res)
		return res;
	} catch (e) {
		throw new Error(e);
	}
};

const remove = async (key) => {
	try {
		let res = await localforage.removeItem(key);
		//console.debug(res)
		return res;
	} catch (e) {
		throw new Error(e);
	}
};

const listItems = async () => {
	try {
		let res = await localforage.keys();
		//console.debug(res)
		return res;
	} catch (e) {
		throw new Error(e);
	}
};

const clear = async () => {
	try {
		let res = await localforage.clear();

		return res;
	} catch (e) {
		throw new Error(e);
	}
};

export { set, get, remove, listItems, clear };
