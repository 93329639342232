<template>
	<ion-app>
		<ion-router-outlet />
	</ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";

import { setEndpointURL } from "@/services/api.js";

export default {
	name: "App",
	components: {
		IonApp,
		IonRouterOutlet,
	},
	data() {
		return {
			signedIn: false,
		};
	},
	created() {
		if (window.location.hostname != "join.runxact.com") {
			setEndpointURL("http://" + window.location.hostname + ":4962/api/test");
		}
	},

	computed: {
		isOnline() {
			return this.$store.state.isOnline;
		},
	},
};
</script>
