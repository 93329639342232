/* eslint-disable no-async-promise-executor */
import axios from "axios";
import { store } from "../store/index.js";

import { getIdToken, getCurrentAuthUser } from "./firebase";
import { get } from "./storage";

let secureEndpointURL = "https://1zdyrb4nv0.execute-api.eu-west-2.amazonaws.com/api/v1";

const setEndpointURL = (location) => {
	secureEndpointURL = location;
	//console.debug(location);
};

const getUid = () => {
	if (store.state.overrideUid) return store.state.overrideUid;
	else return getCurrentAuthUser().uid;
};

const getEmail = () => getCurrentAuthUser().email;

const startBilling = async (backend, period, frontend, customer, source, sourceid, currency) => {
	const url = secureEndpointURL + "/stripe/create";
	const uid = await getUid();
	let idToken = await getIdToken();
	console.debug(uid);

	let res = await axios.get(url, {
		params: {
			backend,
			uid,
			period,
			frontend,
			customer,
			email: await getEmail(),
			source,
			sourceid,
			currency,
		},
		headers: {
			Authorization: idToken,
		},
	});

	console.debug(res);

	return res.data.url;
};

const signupWriteProfile = async (programs) => {
	const spercent = {
		IDLE: 0.475,
		REC: 0.475,
		AER: 0.625,
		ST: 0.745,
		MP: 0.84,
		TEMPO: 0.875,
		HM: 0.91,
		AT: 0.94,
		"10K": 0.96,
		"8K": 0.98,
		"5K": 1,
		"3K": 1.035,
		AN: 1.1,
		SP: 1.18,
		SPRINT: 1.18,
	};

	let data;

	let personalDetails = await get("PersonalDetails");

	console.debug(`personalDetails`);
	console.debug(personalDetails);

	let programName = await get("programName");
	let dayInfo = await get("programDayData");

	let uid = await getUid();
	let idToken = await getIdToken();

	//TODO adjusted conversion to 5K - DONE
	//
	const calculateCurrentFitness = (distance, hours, mins, secs) => {
		console.debug(`distance: ${distance}, hours: ${hours}, mins: ${mins}, secs: ${secs}`);
		const time = +hours * 3600 + +mins * 60 + +secs;

		console.debug("Time: " + time);

		const cameron =
			((time /
				(((3600 /
					(13.49681 -
						(0.048865 * distance) / 1.609 +
						2.438936 / (distance / 1.609) ** 0.7905)) *
					distance) /
					1.609)) *
				(3600 / (13.49681 - 0.048865 * (5 / 1.609) + 2.438936 / (5 / 1.609) ** 0.7905)) *
				(5 / 1.609)) /
			5;

		let calculatedTime = cameron;

		if (distance < 3) {
			let pace = time / distance;
			let percentage;

			if (distance <= 0.8) {
				percentage = spercent.SP;
			} else if (distance < 1.5) {
				percentage =
					spercent.SP + ((distance - 0.8) * (spercent["AN"] - spercent["SP"])) / 0.7;
			} else if (distance == 1.5) {
				percentage = spercent.AN;
			} else {
				spercent.AN + ((distance - 1.5) * (spercent["3K"] - spercent["AN"])) / 1.5;
			}

			calculatedTime = pace / (2 - percentage);
		}

		return {
			time: calculatedTime,
			distance: 5,
			startingDist: distance,
		};
	};

	const {
		bestPerformanceDist,
		bestPerformanceHours,
		bestPerformanceMinutes,
		bestPerformanceSeconds,
	} = personalDetails;

	//console.debug(bestPerformanceDist, bestPerformanceHours, bestPerformanceMinutes, bestPerformanceSeconds)

	let calcRaceString = () => {
		let string = "";

		if (+bestPerformanceHours < 10) {
			string += `0${bestPerformanceHours}:`;
		} else string += bestPerformanceHours + ":";

		if (+bestPerformanceMinutes < 10) {
			string += `0${bestPerformanceMinutes}:`;
		} else string += bestPerformanceMinutes + ":";

		if (+bestPerformanceSeconds < 10) {
			string += `0${bestPerformanceSeconds}:`;
		} else string += bestPerformanceSeconds;

		return string;
	};

	const overrideString = await calcRaceString();

	store.commit("setRaceTimeString", overrideString);

	let currentfitness = calculateCurrentFitness(
		bestPerformanceDist,
		bestPerformanceHours,
		bestPerformanceMinutes,
		bestPerformanceSeconds
	);
	const raceDist = +personalDetails.raceDist;

	console.debug(currentfitness);

	switch (programs) {
		case true:
			data = {
				lastName: personalDetails.lastName,
				firstName: personalDetails.firstName,
				currentfitness,
				refreshToken: "",
				accessToken: "",
				target: {
					distance: raceDist,
				},
				programs: true,
				programData: {
					program: programName,
					endDate: personalDetails.goalDate,
					target: +personalDetails.weekDist,
					days: {
						1: dayInfo[1],
						2: dayInfo[2],
						3: dayInfo[3],
						4: dayInfo[4],
						5: dayInfo[5],
						6: dayInfo[6],
						7: dayInfo[7],
					},
				},
			};

			break;
		case false:
			data = {
				lastName: personalDetails.lastName,
				firstName: personalDetails.firstName,
				currentfitness,
				accessToken: "",
				refreshToken: "",
				target: {
					distance: +personalDetails.raceDist,
				},
				//weekDist: +personalDetails.weekDist,
				//raceDate: personalDetails.goalDate,
				programs: false,
				dob: personalDetails.dob,
				overrideString,
			};
			break;
	}

	console.debug(data);

	let params = {
		uid,
		create: true,
		populate: true,
		templateData: false,
		data: JSON.stringify(data),
	};

	let res = await axios.get(secureEndpointURL + "/db/createTables", {
		params,
		headers: {
			Authorization: idToken,
			"Content-Type": "application/json",
		},
	});

	console.debug(res);
};

const stravaAuth = async (protocol, replace = false) => {
	const uid = await getUid();
	console.debug(uid);
	let url = "https://strava.com/oauth/mobile/authorize?";

	let params = {
		client_id: 51060,
		response_type: "code",
		redirect_uri:
			"https://3v9jxh7o04.execute-api.eu-west-2.amazonaws.com/api/v1/strava/auth?protocol=" +
			protocol +
			"replace=" +
			replace,
		approval_prompt: "force",
		scope: "activity:read_all",
		state: uid,
	};

	for (const query in params) {
		url += query + "=" + params[query] + "&";
	}

	console.debug(url);

	url = url.slice(0, -1);

	console.debug(url);

	return url;
};

const garminAuth = async (protocol, replace = false) => {
	const url = secureEndpointURL + "/garmin/auth/init";
	const uid = await getUid();
	let idToken = await getIdToken();
	console.debug(uid);

	let res = await axios.get(url, {
		params: {
			uid: uid,
			protocol: protocol,
			replace: replace,
		},
		headers: {
			Authorization: idToken,
		},
	});

	console.debug(res);

	return res.data.redirect_uri;
};

const corosAuth = async (protocol, replace = false) => {
	const uid = await getUid();
	console.debug(uid);
	let url = `https://${process.env.NODE_ENV === "production" ? "open" : "opentest"}.coros.com/oauth2/authorize?`;

	let params = new URLSearchParams({
		client_id: "9b94a54e89374168b834870400aaaf12",
		response_type: "code",
		redirect_uri:
		"https://3v9jxh7o04.execute-api.eu-west-2.amazonaws.com/api/v1" +
		"/coros/auth?protocol=" +
		protocol +
		"&replace=" +
		replace,
		state: uid,
	});

	return url + params.toString();
};

export { signupWriteProfile, corosAuth, stravaAuth, garminAuth, setEndpointURL, startBilling, getEmail };
