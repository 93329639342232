<template>
	<Ion-page>
		<ion-header style="height: 60px">
			<ion-toolbar class="ion-no-padding">
				<img
					height="50"
					src="assets/Runxact_Logo_Logotype.svg"
					class="center"
				/>
				<slot name="toolbar" />
			</ion-toolbar>
		</ion-header>
		<ion-content :scroll-events="true" @ionScroll="scroll">
			<div class="centeredView">
				<slot style="padding: 10px" />
			</div>
		</ion-content>
	</Ion-page>
</template>

<script>
	import { IonPage, IonHeader, IonContent, IonToolbar } from "@ionic/vue";

	export default {
		components: {
			IonPage,
			IonHeader,
			IonToolbar,
			IonContent,
		},
	};
</script>

<style scoped>
	.center {
		margin-left: 15px;
		display: block;
	}
	.ion-back-button {
		color: var(--ion-color-primary);
	}
	.centeredView {
		max-width: 1000px;
		margin: auto;
		color: var(--ion-color-step-150);
	}
	.settings {
		color: black;
		position: absolute;
		right: 10px;
		top: calc(50% - 11px);
		font-size: 22px;
	}
	ion-col {
		margin: 0 6px;
	}
</style>
