import { createRouter, createWebHistory } from "@ionic/vue-router";
//import { RouteRecordRaw } from 'vue-router';

const routes = [
	{
		path: "/",
		component: () => import("@/views/Auth/SignUp.vue"),
	},
	{
		path: "/signin/signup/details1",
		component: () => import("@/views/Auth/SignUpDetails1.vue"),
	},
	{
		path: "/signin/signup/details2",
		component: () => import("@/views/Auth/SignUpDetails2.vue"),
	},
	{
		path: "/signin/signup/details3",
		component: () => import("@/views/Auth/SignUpDetails3.vue"),
	},
	{
		path: "/signin/signup/details4",
		component: () => import("@/views/Auth/SignUpDetails4.vue"),
	},
	{
		path: "/signin/signup/details5",
		component: () => import("@/views/Auth/SignUpDetails5.vue"),
	},
	{
		path: "/continue",
		component: () => import("@/views/Auth/ContinueSignUp.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
