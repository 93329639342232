const firebaseConfig = {
	apiKey: "AIzaSyAOKe6iGG_n8QSUwjmprKJD7k_-Z5r7FHA",
	authDomain: "macro-storm-314519.firebaseapp.com",
	projectId: "macro-storm-314519",
	storageBucket: "macro-storm-314519.appspot.com",
	messagingSenderId: "819200473415",
	appId: "1:819200473415:web:ddabcaa07ea46db5a7b462",
	measurementId: "G-DCTGY43ZWM",
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();

import firebase from "firebase";

import { store } from "../store/index.js";

let messaging;

try {
	messaging = firebase.messaging();
} catch (e) {
	console.debug(e);
	messaging = null;
}

const functions = firebase.functions();

const emailSignUp = (email, password) => {
	console.debug(email, password);
	return new Promise((resolve, reject) => {
		auth.createUserWithEmailAndPassword(email, password)
			.then((res) => {
				console.debug("register success");
				auth.currentUser.sendEmailVerification();
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const emailSignIn = (email, password) => {
	console.debug(email, password);
	return new Promise((resolve, reject) => {
		auth.signInWithEmailAndPassword(email, password)
			.then((res) => {
				console.debug("register success");
				resolve(res);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const resetPassword = (email) => {
	return new Promise((resolve, reject) => {
		auth.sendPasswordResetEmail(email)
			.then((res) => resolve(res))
			.catch((e) => reject(e));
	});
};

const getCurrentAuthUser = () => {
	return auth.currentUser;
};

const signOut = () => {
	return new Promise((resolve, reject) => {
		auth.signOut()
			.then((res) => resolve(res))
			.catch((e) => reject(e));
	});
};

const googleAuth = () => {
	return new Promise((resolve, reject) => {
		try {
			/*
        cfaSignIn("google.com").subscribe((user) => {
          console.debug(user);
          resolve(user);*/
			const provider = new firebase.auth.GoogleAuthProvider();
			auth.signInWithPopup(provider).then((res) => {
				let user = res.user;
				console.debug(user);
				resolve(user);
			});
		} catch (e) {
			reject(e);
		}
	});
};

const getGoogleRedirect = () => {
	return new Promise((resolve, reject) => {
		auth.getRedirectResult()
			.then((res) => {
				console.debug("redirect result");
				const credential = res.credential;
				const token = credential.accessToken;
				const user = res.user;

				console.debug(credential, token, user);

				resolve(user);
			})
			.catch((e) => reject(e));
	});
};

const getIdToken = () => {
	return new Promise((resolve, reject) => {
		auth.currentUser
			.getIdToken(true)
			.then((token) => resolve(token))
			.catch((e) => reject(e));
	});
};

const getIdTokenResult = () => {
	return new Promise((resolve, reject) => {
		auth.currentUser
			.getIdTokenResult()
			.then((result) => {
				resolve(result);
			})
			.catch((e) => reject(e));
	});
};

const authStateObserver = () => {
	return new Promise((resolve, reject) => {
		auth.onAuthStateChanged(
			(user) => {
				if (user) {
					resolve(user);
				} else {
					resolve(null);
				}
			},
			(err) => {
				reject(err);
			}
		);
	});
};

const getTempToken = (password) => {
	return functions.httpsCallable("getTemporaryPasswordFromToken")({ password: password });
};

const tokenSignIn = (password) => {
	return new Promise((resolve, reject) => {
		getTempToken(password).then((token) => {
			auth.signInWithCustomToken(token.data).then(resolve).catch(reject);
		});
	});
};

const getFCMToken = () => {
	console.debug(messaging);
	messaging
		.getToken({
			vapidKey:
				"BOidE_KViZ66XxPMkdeTtDFbNicsjwpHnvV_DsnNDFyRbJwwVQD8NjEZZNQt1QPCUknLKben7NxQXsbOQfUDObg",
		})

		.then((currentToken) => {
			console.debug(currentToken);
			if (currentToken) {
				store.commit("setClientToken", currentToken);
				return currentToken;
			} else {
				return null;
			}
		})
		.catch((err) => {
			console.debug(err);
			return null;
		});
};

export {
	emailSignUp,
	getCurrentAuthUser,
	emailSignIn,
	resetPassword,
	signOut,
	googleAuth,
	getGoogleRedirect,
	getIdToken,
	authStateObserver,
	messaging,
	getFCMToken,
	getIdTokenResult,
	tokenSignIn,
};
