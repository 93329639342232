import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "vuex-shared-mutations";
import createCache from "vuex-cache";

const defaultState = () => {
	return {
		refreshKey: Math.random(),
		lastRefreshKey: null,
		deferredPrompt: null,
		clientToken: null,
		userProfile: null,
		lastWorkout: null,
		lastSession: null,
		activityToScore: null,
		activities: null,
		raceTimeString: null,
		resetDate: null,
		rescoreData: null,
		overrideUid: null,
		races: null,
		showRacePerformances: false,
		userSpeeds: null,
		lastRace: null,
		v2Activities: {
			sessions: null,
			nonSessions: null,
			all: null,
		},
		cycleStartRace: null,
		stats: null,
		mixIssue: false,
		buildNo: "0.14.0",
		isOnline: true,
	};
};

export const store = createStore({
	state: defaultState(),
	plugins: [
		createPersistedState(),
		createMutationsSharer({
			predicate: [
				"setUserProfile",
				"setDeferredPrompt",
				"setClientToken",
				"setLastWorkout",
				"setLastSession",
				"setActivityToScore",
				"setActivities",
				"setRaceTimeString",
				"setResetDate",
				"setRescoreData",
				"setOverrideUid",
				"setRaces",
				"toggleShowRaces",
				"setUserSpeeds",
				"setLastRace",
				"setV2Activities",
				"setCycleStartRace",
				"setStats",
				"setMixIssue",
			],
		}),
		createCache({ timeout: 30000 }),
	],
	mutations: {
		clearStore(state) {
			Object.assign(state, defaultState());
		},
		setUserProfile(state, userProfile) {
			state.userProfile = userProfile;
		},
		setDeferredPrompt(state, prompt) {
			state.deferredPrompt = prompt;
		},
		setClientToken(state, token) {
			state.clientToken = token;
		},
		setLastWorkout(state, lastWorkout) {
			state.lastWorkout = lastWorkout;
		},
		setLastSession(state, lastSession) {
			state.lastSession = lastSession;
		},
		setActivityToScore(state, actObj) {
			state.activityToScore = actObj;
		},
		setActivities(state, actObj) {
			state.activities = actObj;
		},
		setRaceTimeString(state, rTime) {
			state.raceTimeString = rTime;
		},
		setResetDate(state, date) {
			state.resetDate = date;
		},
		setRescoreData(state, data) {
			state.rescoreData = data;
		},
		setOverrideUid(state, data) {
			state.overrideUid = data;
		},
		setRaces(state, races) {
			state.races = races;
		},
		toggleShowRaces(state) {
			state.showRacePerformances = !state.showRacePerformances;
		},
		setUserSpeeds(state, speeds) {
			state.userSpeeds = speeds;
		},
		setLastRace(state, bool) {
			state.lastRace = bool;
		},
		setV2Activities(state, obj) {
			state.v2Activities = obj;
		},
		regenRefreshKey(state) {
			state.refreshKey = Math.random();
		},
		setLastRefreshKey(state, key) {
			state.lastRefreshKey = key;
		},
		setCycleStartRace(state, race) {
			state.cycleStartRace = race;
		},
		setStats(state, stats) {
			state.stats = stats;
		},
		setMixIssue(state, bool) {
			if (typeof bool != "boolean") state.mixIssue = false;
			else state.mixIssue = bool;
		},
		setOnline(state, bool) {
			state.isOnline = bool;
		},
	},
});
